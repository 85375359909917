import { MentionType } from "../../state";
import ForceGraph3D from "react-force-graph-3d";
import { Box, Typography } from "@mui/material";
import { GlobalSizes } from "../../size";
import * as THREE from "three";
import MentionFocus from "../MentionFocus";
import { similarityThreshold } from "../../labels";
import { useEffect, useMemo, useState } from "react";
import { useTheme } from "@mui/material";
import { useNetworkChart } from "../../useNetworkChart";

function EmbeddingsNetworkChart({
  mentions,
  fullScreen,
  selectedNode,
  onNodeClick,
  onBackgroundClick,
  getSeverityColor,
}: {
  mentions: MentionType[];
  fullScreen: boolean;
  selectedNode: string | null;
  onNodeClick: (node: any) => void;
  onBackgroundClick: () => void;
  getSeverityColor: (similarity: number) => string;
}) {
  const [imgCache, setImgCache] = useState<{ [key: string]: THREE.Texture }>({});
  const theme = useTheme();
  const { getLinkParticles, getLinkWidth, getLinkParticleWidth } = useNetworkChart(selectedNode);

  const { myData } = useMemo(() => {
    const myData = {
      nodes: [] as any[],
      links: [] as any[],
    };

    for (const mention of mentions) {
      const node = {
        id: mention.url,
        name: mention.description_short || mention.title || mention.url,
        image: mention.avatar,
        source: mention.source,
        val: 1,
      };

      myData.nodes.push(node);

      for (const similarMention of mention.similar || []) {
        if (similarMention.score < similarityThreshold) continue;
        if (mentions.findIndex((m) => m.url === similarMention.url) === -1) continue;
        if ((mention.creation_date || 0) > (similarMention.creation_date || 0)) {
          myData.links.push({
            source: similarMention.url,
            target: mention.url,
            similarity: similarMention.score,
          });
        } else {
          myData.links.push({
            source: mention.url,
            target: similarMention.url,
            similarity: similarMention.score,
          });
        }
      }
    }

    return { myData };
  }, [mentions]);

  const createCircularGlowTexture = (color: string) => {
    const size = 256;
    const canvas = document.createElement("canvas");
    canvas.width = size;
    canvas.height = size;

    const ctx = canvas.getContext("2d");
    if (ctx) {
      ctx.beginPath();
      ctx.arc(size / 2, size / 2, size / 3, 0, Math.PI * 2, true);
      ctx.closePath();
      ctx.clip();
      ctx.fillStyle = color;
      ctx.fill();
    }

    const texture = new THREE.Texture(canvas);
    texture.needsUpdate = true; // Mark the texture for update
    const glowSprite = new THREE.Sprite(
      new THREE.SpriteMaterial({
        map: texture,
        depthWrite: false,
      })
    );
    glowSprite.scale.set(19 * 2, 19 * 2, 1);
    return glowSprite;
  };

  const nodeThreeObject = useMemo(() => {
    return (node: any) => {
      const group = new THREE.Group();
      const isImageNode = imgCache[node.image];

      if (isImageNode) {
        const sprite = new THREE.Sprite(
          new THREE.SpriteMaterial({
            map: imgCache[node.image],
            transparent: true,
            depthWrite: false,
          })
        );
        sprite.scale.set(20, 20, 1);
        sprite.renderOrder = 2;
        group.add(sprite);
      } else {
        const sphere = new THREE.Mesh(new THREE.SphereGeometry(3), new THREE.MeshBasicMaterial({ color: "grey" }));
        group.add(sphere);
      }
      const isSelected = selectedNode === node.id;
      const relatedLink = myData.links.find(
        (link) => (link.source.id === selectedNode && link.target.id === node.id) || (link.target.id === selectedNode && link.source.id === node.id)
      );
      const isRelated = Boolean(relatedLink);
      if (isSelected || isRelated) {
        const highlightColor = isRelated ? getSeverityColor(relatedLink.similarity) : "#9966ff";
        const glowSprite = createCircularGlowTexture(highlightColor);
        group.add(glowSprite);
      }

      return group;
    };
  }, [imgCache, selectedNode, myData.links, getSeverityColor]);

  useEffect(() => {
    // Preload images and cache them with circular masking
    myData.nodes.forEach((node) => {
      if (node.image && !imgCache[node.image]) {
        const canvas = document.createElement("canvas");
        const size = 64;
        canvas.width = size;
        canvas.height = size;
        const ctx = canvas.getContext("2d");

        if (ctx) {
          const img = new Image();
          img.crossOrigin = "Anonymous";

          img.onload = () => {
            ctx.clearRect(0, 0, size, size);
            ctx.save();
            ctx.beginPath();
            ctx.arc(size / 2, size / 2, size / 2, 0, Math.PI * 2, true);
            ctx.closePath();
            ctx.clip();

            ctx.drawImage(img, 0, 0, size, size);
            ctx.restore();

            const texture = new THREE.Texture(canvas);
            texture.needsUpdate = true;

            setImgCache((prevCache) => ({ ...prevCache, [node.image]: texture }));
          };

          img.src = node.image;
        }
      }
    });
  }, [imgCache, myData.nodes]);

  return (
    <Box>
      <Typography variant="h6" gutterBottom ml={GlobalSizes.gap}>
        AI Narrative Network (Showing {mentions.length} from top 1000 alerts)
      </Typography>
      <ForceGraph3D
        graphData={myData}
        d3AlphaDecay={0.03} // Slows down stabilization
        d3VelocityDecay={0.4} // Reduces jitter
        cooldownTime={2000} // Limits warm-up duration
        cooldownTicks={0}
        width={fullScreen ? window.innerWidth : GlobalSizes.cards.width}
        height={fullScreen ? window.innerHeight - 98 : 500}
        warmupTicks={100}
        enableNodeDrag={false}
        backgroundColor={theme.palette.background.paper}
        nodeLabel={(node) => `<div style="color: gray; font-weight: bold;">${node.source} - ${node.name}</div>`}
        linkWidth={getLinkWidth}
        linkDirectionalParticles={getLinkParticles}
        linkDirectionalParticleWidth={getLinkParticleWidth}
        linkOpacity={0.8}
        linkLabel={(link) => `<div style="color: gray; font-weight: bold;">${link.similarity.toFixed(2) * 100 + "% similar"}</div>`}
        linkColor={(link) => getSeverityColor(link.similarity)}
        nodeThreeObject={nodeThreeObject}
        onNodeClick={onNodeClick}
        onBackgroundClick={onBackgroundClick}
        rendererConfig={{
          powerPreference: "high-performance",
        }}
      />
      <MentionFocus />
    </Box>
  );
}

export default EmbeddingsNetworkChart;
