import { useCallback, useEffect, useState } from "react";
import { Container, Button, Box, Stack, CircularProgress, Typography, Tabs, Tab } from "@mui/material";
import Loader from "../Components/Loader";
import Bar from "../Components/Bar";
import { MITIGATIONS, labels } from "../labels";
import { MentionType, MitigationType } from "../state";
import { GlobalSizes, pagesContainerMargins } from "../size";
import { Link } from "react-router-dom";
import { useLocation } from "react-router-dom";
import RefreshButton from "../Components/RefreshButton";
import { useWsContext } from "../ws-context";
import AlertSelect from "../Components/AlertSelect";
import GeneralView from "../Components/investigation/GeneralView";
import MitigationTabPanel from "../Components/action/MitigationTabPanel";
import { Check } from "@mui/icons-material";

export type MitigationKeys = keyof typeof MITIGATIONS;
type MitigationEntry = {
  promptId: string;
  emails: string[];
  supportedDomains?: string[];
};

function Action() {
  const [selectedTab, setSelectedTab] = useState<string>();
  const [isLoading, setIsLoading] = useState<{ takedown: boolean; dossier: string }>({
    takedown: false,
    dossier: "",
  });

  const [dossierError, setDossierError] = useState(false);

  const { mentionsState, updateAlerts, emitNewData, socketRef, currentAlert, setCurrentAlert } = useWsContext();

  const location = useLocation();

  const handleTakedown = async (alert: MentionType) => {
    emitNewData({ action: "takedown", url: currentAlert?.url });
    updateAlerts({ ...alert, takedown_status: "submitted" });
  };

  const createDossierFile = async (alert: MentionType) => {
    setIsLoading((prev) => ({ ...prev, dossier: "loading" }));
    setDossierError(false);
    updateAlerts({ ...alert, mitigation_overview: undefined });
    emitNewData({ action: "dossier", url: alert.url });
    const handleWebSocketMessage = (event: MessageEvent) => {
      const { data } = JSON.parse(event.data);
      if (data?.updatedAlert?.url === alert.url && data.updatedAlert.mitigation_overview) {
        setIsLoading((prev) => ({ ...prev, dossier: "" }));
        socketRef.current?.removeEventListener("message", handleWebSocketMessage);
      }
      if (data?.error?.details?.action === "dossier" && data.error.details.url === alert.url) {
        setIsLoading((prev) => ({ ...prev, dossier: "" }));
        setDossierError(true);
        socketRef.current?.removeEventListener("message", handleWebSocketMessage);
      }
    };
    socketRef.current?.addEventListener("message", handleWebSocketMessage);
  };

  const findMitigationSources = useCallback(
    (parentMention: string, source: string | undefined) => {
      const sourceArray: string[] = source ? [source] : [];
      const childAlerts = mentionsState.filter((m) => m.parent_mention === parentMention).map((m) => m.url);

      childAlerts.forEach((key) => {
        try {
          let source = new URL(key.toLowerCase()).host;
          source = source.replace("www.", "");
          if (!sourceArray?.includes(source)) {
            sourceArray?.push(source);
          }
        } catch (error) {
          console.error(`Error parsing URL ${key}:`, error);
        }
      });
      return sourceArray;
    },
    [mentionsState]
  );

  const shouldRenderMitigationButton = useCallback(
    (mitigation: MitigationEntry, alert: MentionType) => {
      if (
        (mitigation.promptId === "mitigation_ai_content_analysis" || mitigation.promptId === "mitigation_counter_narrative") &&
        !alert.ai_filter?.results?.length
      ) {
        return false;
      }
      return (
        !mitigation.supportedDomains ||
        findMitigationSources(alert.url, alert.source)?.some((source) => mitigation.supportedDomains?.some((domain: string) => source === domain))
      );
    },
    [findMitigationSources]
  );

  useEffect(() => {
    const clickedAction = new URLSearchParams(location.search).get("url");
    const alert = mentionsState.find((m) => m.url === clickedAction);
    if (alert) {
      setCurrentAlert(alert);

      const firstTabWithContent = Object.entries(MITIGATIONS).find(([, mitigation]) => {
        const mitigationContent = alert[mitigation.promptId as keyof MentionType] as string;
        return shouldRenderMitigationButton(mitigation, alert) && !!mitigationContent?.length;
      });

      setSelectedTab(
        firstTabWithContent?.[0] || Object.keys(MITIGATIONS).find((key) => shouldRenderMitigationButton(MITIGATIONS[key as MitigationKeys], alert))
      );
    }
  }, [location.search, mentionsState, setCurrentAlert, shouldRenderMitigationButton]);

  const MitigationButtons = (alert: MentionType) => {
    return (
      <Stack sx={{ flexDirection: "row", gap: GlobalSizes.mediumGap, marginTop: GlobalSizes.gap }}>
        <Stack>
          <Button variant="contained" size="large" color="secondary" onClick={() => handleTakedown(alert)} disabled={!!alert.takedown_status}>
            <Stack alignItems="self-start">
              <Typography>Takedown</Typography>
              <Typography variant="caption">social platform removal request</Typography>
              <Typography variant="caption">{alert.takedown_status}</Typography>
            </Stack>
          </Button>
          {isLoading.takedown && <CircularProgress />}
        </Stack>
        <Stack>
          <Button disabled={isLoading.dossier === "loading"} variant="contained" size="large" onClick={() => createDossierFile(alert)}>
            <Stack alignItems="self-start">
              <Typography>Overview</Typography>
              <Typography variant="caption">Generate investigation file</Typography>
            </Stack>
          </Button>
          {alert.mitigation_overview && !isLoading.dossier && (
            <Link target="_blank" to={alert.mitigation_overview}>
              Overview file
            </Link>
          )}
          {isLoading.dossier === "loading" && <CircularProgress sx={{ m: 1 }} />}
        </Stack>
        {isLoading.dossier === "processing" && <RefreshButton label={labels.takeAction.overview} />}
        {dossierError && <Typography color="error">Failed generating overview, please try again</Typography>}
      </Stack>
    );
  };

  const renderContent = (alert: MentionType) => {
    return (
      <Stack key={alert.url}>
        <Typography mb={GlobalSizes.gap} variant="h5">
          Mitigation
        </Typography>
        <Typography>Threat mitigation options</Typography>
        <Stack direction="column" gap={GlobalSizes.gap}>
          <Tabs value={selectedTab} onChange={(e, newValue) => setSelectedTab(newValue)} aria-label="mitigation tabs">
            {Object.entries(MITIGATIONS).map(([mitigationKey, mitigation]) => {
              if (!shouldRenderMitigationButton(mitigation, alert)) return null;
              const mitigationContent = alert[mitigation.promptId as keyof MentionType] as string;
              const hasContent = !!mitigationContent?.length;
              return (
                <Tab
                  key={mitigationKey}
                  label={
                    <Box sx={{ position: "relative" }}>
                      {mitigationKey}
                      {hasContent && <Check sx={{ fontSize: GlobalSizes.largeSize, position: "absolute", right: "-16px" }} />}
                    </Box>
                  }
                  value={mitigationKey}
                  aria-label={mitigationKey}
                />
              );
            })}
          </Tabs>
          {Object.entries(MITIGATIONS).map(([mitigationKey, mitigation]) => {
            const shouldRender = alert.url && shouldRenderMitigationButton(mitigation, alert) && selectedTab === mitigationKey;
            if (!shouldRender) return null;
            const promptId = mitigation.promptId;
            return (
              <MitigationTabPanel
                key={mitigationKey}
                mitigation={mitigationKey as MitigationKeys}
                mitigationText={alert[promptId as keyof MitigationType]}
                alert={alert}
                showReplyInPlatform={(mitigationKey === "counter narrative" || mitigationKey === "alternate narrative") && alert.type === "post" && alert.source === "twitter.com"}
              />
            );
          })}
        </Stack>
      </Stack>
    );
  };

  return (
    <div>
      <Bar label={labels.takeAction.title + " | " + labels.takeAction.subtitle} />
      <Container sx={{ ...pagesContainerMargins, minWidth: GlobalSizes.fullSize }}>
        <Box sx={{ width: GlobalSizes.fullSize, typography: "body1" }}>
          <AlertSelect destination="action" />
          {currentAlert && MitigationButtons(currentAlert)}
          <GeneralView alert={currentAlert} />
          {currentAlert && renderContent(currentAlert)}
          <Loader />
        </Box>
      </Container>
    </div>
  );
}

export default Action;
