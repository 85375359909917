import { Avatar, ListItem, ListItemAvatar, ListItemText, Typography } from "@mui/material";
import { MentionType } from "../state";
import SeverityChip from "./SeverityChip";
import { useWsContext } from "../ws-context";

const MentionListItem = ({ alert, primaryText }: { alert: MentionType; primaryText?: string }) => {
  const { setSidebarAlert } = useWsContext();

  if (!alert) return null;

  return (
    <ListItem
      key={alert.url}
      onClick={() => {
        setSidebarAlert(alert);
      }}
      sx={{
        cursor: "pointer",
        "&:hover": {
          backgroundColor: "#55555555",
        },
      }}
      alignItems="flex-start"
    >
      <ListItemAvatar>
        <Avatar alt={alert.user} src={alert.avatar} />
      </ListItemAvatar>
      <ListItemText
        primary={
          <>
            {primaryText ?? ""} <SeverityChip alert={alert} />
          </>
        }
        secondary={
          <>
            <Typography sx={{ display: "inline" }} component="span" variant="body2" color="text.primary">
              {alert.user}
            </Typography>
            : {alert.description_short} {alert.manual_severity}
          </>
        }
      />
    </ListItem>
  );
};

export default MentionListItem;
