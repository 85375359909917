import {
  Button,
  Card,
  CardContent,
  CardMedia,
  List,
  ListItem,
  ListItemText,
  Stack,
} from "@mui/material";
import { InvestigationSizes, GlobalSizes } from "../../size";
import { TwitterTweetEmbed } from "react-twitter-embed";
import html2canvas from "html2canvas";
import {  MentionType, TwitterUserType } from "../../state";

interface TwitterDataProps {
  twitterUser: TwitterUserType;
  alert: MentionType;
  cardRef: React.RefObject<HTMLDivElement>;
}

function TwitterData({ twitterUser, alert, cardRef }: TwitterDataProps) {
  const followersRatio = twitterUser.public_metrics.followers_count / twitterUser.public_metrics.following_count;
  const followersRatioString = followersRatio.toFixed(2);
  const tweetId = alert?.twitter_data?.data?.id;

  const captureScreenshot = async () => {
    if (cardRef.current && alert?.twitter_data?.data?.id) {
      const canvas = await html2canvas(cardRef.current);
      const image = canvas.toDataURL("image/png");
      const link = document.createElement("a");
      link.href = image;
      link.download = `screenshot_${twitterUser.username}_${alert?.twitter_data.data.id}.png`;
      link.click();
    }
  };

  return (
    <div>
      <Card sx={{ display: "flex", mt: GlobalSizes.gap }}>
        <CardMedia
          component="img"
          sx={{ width: GlobalSizes.largeHeight }}
          height={GlobalSizes.largeHeight}
          image={twitterUser.profile_image_url.replace("_normal", "")}
          title={twitterUser.username}
        />

        <CardContent>
          <List dense>
            <ListItem>
              <ListItemText primary={"@" + twitterUser.username} secondary={twitterUser.verified ? "(Verified)" : "(Unverified)"} />
              <a href={`https://twitter.com/${twitterUser.username}`} target="_blank" rel="noreferrer">
                <Button sx={{ ml: GlobalSizes.gap }} variant="outlined">
                  Show in twitter
                </Button>
              </a>
            </ListItem>
            <ListItem>
              <ListItemText primary={twitterUser.description} secondary="Biography" />
            </ListItem>
            <ListItem>
              <ListItemText primary={twitterUser.created_at} secondary="User created" />
            </ListItem>
            <ListItem>
              <ListItemText
                primary={`${twitterUser.public_metrics.followers_count} / ${twitterUser.public_metrics.following_count} ${
                  followersRatio !== Infinity ? `= ${followersRatioString}` : ""
                }`}
                secondary="followers/following"
              />
            </ListItem>
            <ListItem>
              <ListItemText primary={twitterUser.public_metrics.tweet_count} secondary="Tweets" />
            </ListItem>
          </List>
        </CardContent>
      </Card>
      <Stack direction={"row"} alignItems={"self-start"}>
        <div style={{ width: InvestigationSizes.twitterEmbedDivWidth }}>{tweetId && <TwitterTweetEmbed key={tweetId} tweetId={tweetId} />}</div>
        <Button variant="outlined" onClick={captureScreenshot} sx={{ mt: GlobalSizes.gap }}>
          Screenshot
        </Button>
      </Stack>
    </div>
  );
}

export default TwitterData;
