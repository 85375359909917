import { getGridSingleSelectOperators, GridColDef, GridFilterItem, GridRenderCellParams } from "@mui/x-data-grid";
import { useCallback, useMemo } from "react";
import { labels, severity, similarityThreshold, SOCIAL_INFO, unixToLocaleDate } from "./labels";
import PersonIcon from "@mui/icons-material/Person";
import { Avatar, Badge, Box, Chip, Tooltip } from "@mui/material";
import TroubleshootIcon from "@mui/icons-material/Troubleshoot";
import FindReplaceIcon from "@mui/icons-material/FindReplace";
import { useWsContext } from "./ws-context";
import { Link } from "react-router-dom";
import SeverityChip from "./Components/SeverityChip";
import { AiFilterType, AiResultType, MentionType, ContentLinksType } from "./state";
import { useClientContext } from "./client-context";
import { GlobalSizes } from "./size";
import RefreshAiFilter from "./Components/investigation/ai-filter/RefreshAiFilter";
import AssetHighlighter from "./Components/assets/AssetHighlighter";

function parseNumberWithUnits(str: string) {
  const units = { k: 1e3, m: 1e6, b: 1e9, t: 1e12 };
  const regex = /^(\d+(\.\d+)?)([kmbt]?)$/i;
  const match = str?.match(regex);

  if (!match) return null;

  const [, number, , unit] = match;
  const multiplier = unit ? units[unit.toLowerCase() as keyof typeof units] : 1;
  return parseFloat(number) * multiplier;
}

export const useColumns = (isListView?: boolean) => {
  const { mentionsState } = useWsContext();
  const { stringToColor } = useClientContext();

  const createAiFilterOptions = (mentionsState: MentionType[]) => {
    const aiFilterOptions: Record<string, number> = {};

    mentionsState.forEach((mention) => {
      if (mention.ai_filter?.results?.length) {
        mention.ai_filter.results.forEach((result) => {
          const key = result?.key;
          if (key) {
            aiFilterOptions[key] = (aiFilterOptions[key] || 0) + 1;
          }
        });
      } else if (mention.ai_filter?.status) {
        aiFilterOptions[mention.ai_filter.status] = (aiFilterOptions[mention.ai_filter.status] || 0) + 1;
      }
    });

    return Object.entries(aiFilterOptions)
      .sort((a, b) => b[1] - a[1])
      .map(([key, count]) => ({ value: key, label: `${key} (${count})` }));
  };

  const valueOptions = useMemo(() => {
    const fields = ["source", "alertType", "type", "asset", "distribution"] as const;

    const options: Record<string, Record<string, number>> = fields.reduce((acc, field) => {
      acc[field] = {};
      return acc;
    }, {} as Record<string, Record<string, number>>);

    mentionsState.forEach((mention) => {
      fields.forEach((field) => {
        const value = mention[field];
        if (value) {
          options[field][value] = (options[field][value] || 0) + 1;
        }
      });
    });

    // Convert options to sorted arrays with value-label objects
    const formattedOptions = fields.reduce((acc, field) => {
      acc[field] = Object.entries(options[field])
        .sort((a, b) => b[1] - a[1])
        .map((tuple) => ({ value: tuple[0], label: `${tuple[0]} (${tuple[1]})` }));
      return acc;
    }, {} as Record<string, { value: string; label: string }[]>);

    formattedOptions["ai_filter"] = createAiFilterOptions(mentionsState);
    return formattedOptions;
  }, [mentionsState]);

  const filterFunctions: Record<string, (cellValue: string[], filterValue: string | string[]) => boolean> = {
    is: (cellValue, filterValue) => cellValue.includes(filterValue as string),
    not: (cellValue, filterValue) => !cellValue.includes(filterValue as string),
    isAnyOf: (cellValue, filterValue) => (filterValue as string[]).some((selectedOption) => cellValue.includes(selectedOption)),
  };

  const getFilter = (comparator: (cellValue: string[], filterValue: string | string[]) => boolean) => {
    return (filterItem: GridFilterItem) => {
      if (!filterItem.value || !filterItem.value.length) {
        return null;
      }

      return (cellValue?: string[]) => {
        if (!cellValue || !cellValue.length) {
          return comparator === filterFunctions.not;
        }
        return comparator(cellValue, filterItem.value);
      };
    };
  };

  const aiFilterOperators = getGridSingleSelectOperators().map((operator) => {
    if (filterFunctions[operator.value]) {
      return {
        ...operator,
        getApplyFilterFn: getFilter(filterFunctions[operator.value]),
      };
    }
    return operator;
  });

  const aiFilterValueGetter = useCallback((cell: AiFilterType, params: MentionType) => {
    const selectedAiFilter: AiFilterType = cell || params.ai_filter_beta;
    if (!selectedAiFilter) {
      return "0000003";
    }
    if (!selectedAiFilter.results) {
      const status = selectedAiFilter.status;
      if (status === "nothing_found") return `0000004 ${status}`;
      if (status === "processing") return `0000005 ${status}`;
      if (status === "failed") return `0000001 ${status}`;
      if (status === "skipped") return `0000002 ${status}`;
    }
    const trueAnswers = selectedAiFilter.results?.filter((q: AiResultType) => {
      return q.content?.booleanAnswer;
    });
    return trueAnswers?.length.toString().padStart(5, "0") + " " + trueAnswers?.map((q) => q.key).join(", ");
  }, []);

  const aiFilterRenderCell = useCallback(
    (aiFilter: AiFilterType, params: GridRenderCellParams) => {
      let selectedAiFilter: AiFilterType = aiFilter || params.row.ai_filter_beta;
      if (!selectedAiFilter) {
        return null;
      }
      const fromSimilarFilter = selectedAiFilter.reason === "Inherited";
      if (!selectedAiFilter.results) {
        return (
          <Tooltip title={selectedAiFilter.reason}>
            <Chip size="small" label={selectedAiFilter.status} />
          </Tooltip>
        );
      }

      const tableTrueQuestions = selectedAiFilter.results?.map((value: AiResultType, index: number) => (
        <Tooltip title={value.content?.explanation} key={index}>
          <Chip size="small" label={value.key} sx={{ backgroundColor: stringToColor(value.key), color: "black", marginLeft: GlobalSizes.smallGap }} />
        </Tooltip>
      ));

      const listTrueQuestions = selectedAiFilter.results?.map((value: AiResultType, index: number) => (
        <Tooltip title={value.key} key={index}>
          <Box
            sx={{
              width: 25,
              height: 5,
              borderRadius: "25%",
              backgroundColor: stringToColor(value.key),
              display: "inline-block",
              marginLeft: GlobalSizes.smallGap,
            }}
          />
        </Tooltip>
      ));

      return (
        <div>
          <Tooltip key={params.row.url} title={labels.ai.success_count(tableTrueQuestions.length) + (fromSimilarFilter ? " (from similar alert)" : "")}>
            <Chip
              size="small"
              icon={fromSimilarFilter ? <FindReplaceIcon /> : <TroubleshootIcon />}
              label={tableTrueQuestions.length}
              sx={{ marginRight: GlobalSizes.smallGap }}
            />
          </Tooltip>
          {isListView ? listTrueQuestions : tableTrueQuestions}
        </div>
      );
    },
    [isListView, stringToColor]
  );

  const columns: GridColDef[] = useMemo(
    () => [
      {
        field: "avatar",
        description: "The profile picture of the user or the logo of the news source",
        headerName: "👤",
        width: 60,
        renderCell: (params: GridRenderCellParams) => {
          const SelectedIcon = SOCIAL_INFO[params.row.source]?.icon || PersonIcon;
          return (
            <Badge
              overlap="circular"
              anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
              badgeContent={<SelectedIcon color="disabled" fontSize="small" />}
            >
              <Avatar alt={params.row.user} src={params.value} />
            </Badge>
          );
        },
      },
      {
        field: "user",
        headerName: "User",
        width: 150,
        type: "string" as const,
      },
      {
        field: "source",
        headerName: "Source",
        width: 120,
        type: "singleSelect" as const,
        valueOptions: valueOptions.source,
        valueFormatter: (value: string) => value,
      },
      {
        field: "detection_date",
        headerName: "Detected",
        type: "date" as const,
        valueGetter: (value: Date) => {
          return value ? new Date(value) : 0;
        },
        renderCell: (params) => {
          if (!params.value) return null;
          return (
            <Tooltip title={unixToLocaleDate(params.value)}>
              <span>{params.value.toLocaleDateString(undefined, { month: "short", day: "numeric" })}</span>
            </Tooltip>
          );
        },
      },
      {
        field: "creation_date",
        headerName: "Created",
        description: "The date the item was published",
        minWidth: 90,
        type: "date" as const,
        valueGetter: (value: Date) => {
          return value ? new Date(value) : 0;
        },
        renderCell: (params) => {
          if (!params.value) return null;
          return (
            <Tooltip title={unixToLocaleDate(params.value)}>
              <span>{params.value.toLocaleDateString()}</span>
            </Tooltip>
          );
        },
      },
      {
        field: "description_short",
        headerName: "Content",
        description: "The content of the post or article",
        width: 300,
        type: "string" as const,
        renderCell: (params: GridRenderCellParams) => {
          const videoTranscript = params.row.video_transcription ? " Transcript: " + params.row.video_transcription : "";
          const content = params.value || "";
          const asset = params.row.asset;
          const contentWithTranscript = content + videoTranscript;
          if (!contentWithTranscript || !asset) {
            return;
          }
          return (
            <span title={contentWithTranscript}>
              <AssetHighlighter text={contentWithTranscript} asset={asset} />
            </span>
          );
        },
      },
      {
        field: "url",
        headerName: "Link",
        description: "The original URL of the post, user or article",
        width: 300,
        maxWidth: 600,
        type: "string" as const,
        renderCell: (params: GridRenderCellParams) => {
          return (
            <Link to={params.row.url} target="_blank" rel="noopener noreferrer">
              {params.row.url}
            </Link>
          );
        },
      },
      {
        field: "note",
        headerName: "Notes",
        description: "Editable notes for the alert",
        type: "string" as const,
      },
      {
        field: "impressions",
        headerName: "Exposure",
        description: "An estimation of the number of people who have seen the post/article or the number of followers of the user",
        type: "number" as const,
        valueGetter: (value: number | string) => {
          if (typeof value === "string") {
            return parseNumberWithUnits(value);
          }
          return value;
        },
      },
      {
        field: "alertType",
        headerName: "Risk",
        description: "The risk type of the alert",
        type: "singleSelect" as const,
        valueOptions: valueOptions.alertType,
        valueFormatter: (value: string) => value,
      },
      {
        field: "type",
        headerName: "Item",
        description: "The type of the item: post, article, user, etc.",
        type: "singleSelect" as const,
        valueOptions: valueOptions.type,
        valueFormatter: (value: string) => value,
      },
      {
        field: "content_links",
        headerName: "Content Links",
        description: "Links found in the content, displaying the final redirected URL.",
        type: "string" as const,
        valueGetter: (value: ContentLinksType) => {
          return Object.entries(value || {})
            .flatMap(([key, value]) => [value, key])
            .join(",");
        },
        renderCell: (params: GridRenderCellParams) => {
          if (!params.row.content_links) {
            return null;
          }
          return (
            <Box display="flex" gap={1} alignItems="center" height="100%">
              {params.value &&
                Object.entries(params.row.content_links).map(([key, value]) => {
                  return (
                    <Tooltip key={key as string} title={key as string}>
                      <Chip
                        label={value as string}
                        component="a"
                        href={value as string}
                        target="_blank"
                        rel="noopener noreferrer"
                        clickable
                        sx={{ maxWidth: "300px" }}
                        size="small"
                        onClick={(event) => event.stopPropagation()}
                      />
                    </Tooltip>
                  );
                })}
            </Box>
          );
        },
      },
      {
        field: "repost",
        description: "The URL of the original post if this is a repost",
        headerName: "Repost",
        type: "string" as const,
      },
      {
        field: "reply",
        description: "The URL of the post this is a reply to",
        headerName: "Reply",
        type: "string" as const,
      },
      {
        field: "severity",
        headerName: "Severity",
        type: "singleSelect" as const,
        valueGetter: (value: string, params: MentionType) => {
          const finalValue = params?.manual_severity || value || "medium";
          return severity[finalValue.toUpperCase()].value;
        },
        valueOptions: [
          { value: 4, label: "Critical" },
          { value: 3, label: "High" },
          { value: 2, label: "Medium" },
          { value: 1, label: "Low" },
        ],
        renderCell: (params: GridRenderCellParams) => {
          return <SeverityChip alert={params.row} />;
        },
      },
      {
        field: "similar",
        description: "The highest similarity score with another alert",
        headerName: "Similarity",
        width: 120,
        type: "number" as const,
        valueGetter: (value: { score: number }[]) => {
          const score = value?.[0]?.score;
          return score && score >= similarityThreshold ? score * 100 : null;
        },
        renderCell: (params: GridRenderCellParams) => {
          if (params.value === null) return null;
          return (
            <div>
              <Tooltip key={params.row.url} title="Click row to see similar alerts">
                <Chip label={params.value.toFixed(2) + "%"} variant="outlined" />
              </Tooltip>
            </div>
          );
        },
      },
      {
        field: "asset",
        headerName: "Asset",
        description: "The asset associated with the alert",
        type: "singleSelect" as const,
        valueOptions: valueOptions.asset,
        valueFormatter: (value: string) => value,
      },
      {
        field: "location",
        headerName: "Location",
        description: "The location where post/article was published or the location of the user",
        type: "string" as const,
      },
      {
        field: "language",
        headerName: "Language",
        description: "The language the post/article was written in",
        type: "string" as const,
      },
      {
        field: "video_transcription",
        headerName: "Video Transcript",
        description: "Transcription of the video content",
        type: "string" as const,
      },
      {
        field: "distribution",
        headerName: "Distribution",
        description: "Where the article is circulated and read",
        type: "singleSelect" as const,
        valueOptions: valueOptions.distribution,
        valueFormatter: (value: string) => value,
      },
      {
        field: "takedown_status",
        headerName: "Takedown Status",
        description: `The status of the takedown request initiated in the ${labels.takeAction.title} page`,
        type: "string" as const,
      },
      {
        field: "ai_filter",
        headerName: labels.ai.title,
        type: "singleSelect" as const,
        valueOptions: valueOptions.ai_filter,
        filterOperators: aiFilterOperators,
        description: "Narratives that are detected in the content, using AI based on the Narratives defined in the Narratives page or totally new narratives discovered by the AI.",
        valueGetter: (cell: AiFilterType, params: MentionType) => {
          return aiFilterValueGetter(cell, params);
        },
        renderCell: (params: GridRenderCellParams) => {
          return aiFilterRenderCell(params.row.ai_filter, params);
        },
      },
      {
        field: "refresh_ai_filter",
        headerName: `Retry ${labels.ai.title}`,
        description: "Retry the Narrative detection using AI",
        renderCell: (params: GridRenderCellParams) => {
          return <RefreshAiFilter alert={params.row} />;
        },
      },
      {
        field: "status",
        headerName: "Status",
        description: "Archived, Flagged or Whitelisted",
        type: "singleSelect" as const,
        valueGetter: (value: undefined, row: MentionType) => {
          if (row.hidden) return "hidden";
          if (row.flagged) return "flagged";
          if (row.whitelisted) return "whitelisted";
        },
        valueOptions: [
          { value: "hidden", label: "archived" },
          { value: "flagged", label: "flagged" },
          { value: "whitelisted", label: "whitelisted" },
        ],
      },
    ],
    [aiFilterRenderCell, aiFilterValueGetter, valueOptions, aiFilterOperators]
  );

  const listViewColumns = useMemo(() => columns.filter((col) => col.field !== "status"), [columns]);

  return { columns, listViewColumns, aiFilterRenderCell };
};
