import { MentionType } from "../state";
import Typography from "@mui/material/Typography";
import { Box } from "@mui/material";
import { GlobalSizes } from "../size";
import TranscribeIcon from "@mui/icons-material/Transcribe";
import AssetHighlighter from "./assets/AssetHighlighter";
function VideoTranscriptView({ alert }: { alert?: MentionType }) {
  const asset = alert?.asset;
  const videoTranscript = alert?.video_transcription;
  if (!videoTranscript || !asset) return null;
  return (
    <Box mt={GlobalSizes.gap}>
      <Typography variant="h4">
        Video Transcript <TranscribeIcon />
      </Typography>
      <Typography sx={{ marginTop: GlobalSizes.gap }} variant="subtitle1">
        <AssetHighlighter text={alert.video_transcription} asset={asset} />
      </Typography>
    </Box>
  );
}

export default VideoTranscriptView;
