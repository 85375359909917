import { List, Typography, Box } from "@mui/material";
import { GlobalSizes } from "../size";
import { MentionType } from "../state";
import { similarityThreshold } from "../labels";
import MentionListItem from "./MentionListItem";

export function SimilarAlerts({ alert, allMentions }: { alert?: MentionType; allMentions: MentionType[] }) {
  if (!alert?.similar?.length) return null;

  const similarMentionsView = [];
  for (const similarAlert of getSimilarAlerts(alert, allMentions)) {
    if (similarAlert.score < similarityThreshold) continue;
    const similarPercent = (similarAlert.score * 100).toFixed(2) + "%";
    similarMentionsView.push(<MentionListItem key={similarAlert.url} alert={similarAlert} primaryText={similarPercent} />);
  }

  if (!similarMentionsView.length) return null;

  return (
    <Box mt={GlobalSizes.gap}>
      <Typography variant="h4">Similar alerts</Typography>
      <List>{similarMentionsView}</List>
    </Box>
  );
}

export function getSimilarAlerts(alert: MentionType, allMentions: MentionType[]) {
  if (!alert.similar) return [];
  const similarAlerts = [];
  for (const similarMention of alert?.similar || []) {
    const originalMention = allMentions.find((m) => m.url === similarMention.url);
    if (originalMention) {
      const similarAlert = { ...similarMention, ...originalMention };
      similarAlerts.push(similarAlert);
    }
  }
  return similarAlerts;
}

export function getIdenticalAlert(alert: MentionType, allMentions: MentionType[]) {
  const mostSimilar = alert.similar?.[0];
  if (!mostSimilar) return null;
  if (mostSimilar.score < 0.9) return null;
  return allMentions.find((m) => m.url === mostSimilar.url);
}

export function getSimilarSeverity(alert: MentionType, allMentions: MentionType[]) {
  //similar alerts with more than 90% similarity and manual severity
  const verySimilarAlerts = getSimilarAlerts(alert, allMentions).filter((m) => m.manual_severity && m.score >= 0.9);
  if (verySimilarAlerts.length === 0) return null;
  //if the most similar alert is more than 95% similar, return its severity
  if (verySimilarAlerts[0].score > 0.95) return verySimilarAlerts[0].manual_severity;
  //if 3 or more similar alerts have the same manual severity, return that severity
  const similarSeverities: { [key: string]: number } = {};
  for (const similarAlert of verySimilarAlerts) {
    const manualSeverity = similarAlert.manual_severity;
    if (!manualSeverity) continue;
    similarSeverities[manualSeverity] = (similarSeverities[manualSeverity] || 0) + 1;
  }
  for (const severity in similarSeverities) {
    if (similarSeverities[severity] >= 3) return severity;
  }
  return null;
}
