import { useCallback } from "react";

export const useNetworkChart = (selectedNode: string | null) => {
  const getLinkWidth = useCallback(
    (connectedValue: number, defaultValue: number) => {
      return (link: any) => {
        if (!selectedNode) return defaultValue;
        return link.source.id === selectedNode || link.target.id === selectedNode ? connectedValue : defaultValue;
      };
    },
    [selectedNode]
  );

  return {
    getLinkParticles: getLinkWidth(4, 0),
    getLinkWidth: getLinkWidth(2, 1),
    getLinkParticleWidth: getLinkWidth(4, 0),
  };
};
