import { useCallback, useEffect, useMemo, useState } from "react";
import Loader from "../Components/Loader";
import Bar from "../Components/Bar";
import { labels } from "../labels";
import { AssetCard, searchType } from "../Components/assets/AssetCard";
import { GlobalSizes, pagesContainerMargins } from "../size";
import { AssetMapType, entries } from "../state";
import { useWsContext } from "../ws-context";
import { List, FormControl, InputLabel, OutlinedInput, Button, Container, Stack, Typography, Accordion, AccordionDetails, AccordionSummary, } from "@mui/material";
import MentionFocus from "../Components/MentionFocus";
import CollectionCoverage from "../Components/CollectionCoverage";
import debounce from "lodash.debounce";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

const filterAssets = (assets: AssetMapType, searchTerm: string) => {
  if (!searchTerm.trim()) {
    return assets || {};
  }

  return Object.fromEntries(Object.entries(assets || {}).filter(([key]) => key.toLowerCase().includes(searchTerm.toLowerCase())));
};

const groupAssetsByType = (assets: AssetMapType) => {
  return Object.entries(assets).reduce((groupedAssets, [key, value]) => {
    const label = searchType(key).label;
    groupedAssets[label] = groupedAssets[label] || {};
    groupedAssets[label][key] = value;
    return groupedAssets;
  }, {} as Record<string, AssetMapType>);
};

function Assets() {
  const { updateDashboard, dashboardState, emitNewData } = useWsContext();
  const [newAsset, setNewAsset] = useState<string>("");
  const [localAssets, setLocalAssets] = useState<AssetMapType>({});
  const [expanded, setExpanded] = useState<string | null>(null);

  const debouncedFilterAssets = useMemo(
    () =>
      debounce((searchTerm: string) => {
        setLocalAssets(filterAssets(dashboardState.assets || {}, searchTerm));
      }, 300),
    [dashboardState.assets]
  );

  const handleAssetChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const value = e.target.value;
      setNewAsset(value);
      debouncedFilterAssets(value);
    },
    [debouncedFilterAssets]
  );

  const handleExpend = (panel: string) => (event: React.SyntheticEvent, newExpanded: boolean) => {
    setExpanded(newExpanded ? panel : null);
  };

  const saveAsset = async () => {
    if (!newAsset) return;

    const cleanedAsset = newAsset.replace(/\s+/g, " ").trim();

    const updatedAssets = {
      ...(dashboardState.assets || {}),
      [cleanedAsset]: { type: "Term" },
    };

    updateDashboard({
      assets: updatedAssets,
    });

    setLocalAssets(filterAssets(updatedAssets, cleanedAsset));

    emitNewData({
      action: "setAsset",
      asset: {
        [cleanedAsset]: { type: "Term" },
      },
    });

    setNewAsset("");
  };

  const deleteAsset = async (asset: string) => {
    console.log("deleting asset", asset);

    if (!dashboardState.assets) return;

    const updatedAssets = Object.fromEntries(Object.entries(dashboardState.assets).filter(([key]) => key !== asset));

    updateDashboard({
      assets: updatedAssets,
    });

    setLocalAssets(filterAssets(updatedAssets, newAsset));

    emitNewData({
      action: "removeAsset",
      asset: {
        [asset]: dashboardState.assets[asset],
      },
    });
  };

  const hint = searchType(newAsset).label;

  const groupedAssets = useMemo(() => groupAssetsByType(localAssets), [localAssets]);

  useEffect(() => {
    setLocalAssets(filterAssets(dashboardState.assets || {}, newAsset));
  }, [dashboardState.assets, newAsset]);

  useEffect(() => {
    return () => {
      debouncedFilterAssets.cancel();
    };
  }, [debouncedFilterAssets]);

  return (
    <div>
      <Bar label={labels.assets.title + " | " + labels.assets.subtitle} />
      <Container sx={{ ...pagesContainerMargins, minWidth: GlobalSizes.fullSize, marginTop: "5px" }}>
        <Stack direction="column" gap={GlobalSizes.gap}>
          <Stack gap={GlobalSizes.smallGap} padding={1}>
            <CollectionCoverage coverageSources={dashboardState.teamCoverage} />
            <FormControl variant="outlined">
              <InputLabel>Find / Add Asset</InputLabel>
              <OutlinedInput
                onChange={handleAssetChange}
                onKeyUp={(e) => {
                  if (e.key === "Escape") setNewAsset("");
                }}
                value={newAsset}
                id="assetInput"
                label="Find / Add Asset"
              />
              <Button
                variant="contained"
                size="large"
                sx={{ alignSelf: "flex-end", marginTop: 1 }}
                onClick={saveAsset}
                disabled={!newAsset.trim() || entries(localAssets).some(([asset]) => asset.toLowerCase() === newAsset.trim().toLowerCase())}
              >
                <Stack alignItems="self-start">
                  <Typography>Add Asset</Typography>
                  <Typography variant="caption">{hint}</Typography>
                </Stack>
              </Button>
            </FormControl>
          </Stack>
          {entries(groupedAssets).map(([type, assets]) => (
            <Accordion key={type} expanded={!!newAsset || expanded === type} onChange={handleExpend(type)} slotProps={{ transition: { unmountOnExit: true } }}>
              <AccordionSummary expandIcon={newAsset ? null : <ExpandMoreIcon />}>
                <Typography variant="h6" mr={GlobalSizes.gap}>
                  {type.toUpperCase()}
                </Typography>
                <Typography component="span" sx={{ color: "text.secondary" }}>
                  {Object.keys(assets).length} assets
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <List>
                  {entries(assets).map(([key, value]) => (
                    <AssetCard key={key} asset={key} value={value} deleteAsset={deleteAsset} />
                  ))}
                </List>
              </AccordionDetails>
            </Accordion>
          ))}
          <Loader />
        </Stack>
      </Container>
      <MentionFocus />
    </div>
  );
}

export default Assets;
